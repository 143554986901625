<template>
  <!-- 
  1-自定义填写未完成
  2-问卷权限设置未完成
  -->
  <div class="tc-evaluate-bulkImport">
    <div class="tool">
      <ul>
        <li>
          <img src="../../../../assets/images/refreshBtn.png" @click="refresh()" alt />
        </li>
        <li>
          <img src="../../../../assets/images/retreatBtn.png" @click="back()" alt />
        </li>
      </ul>
    </div>
    <el-col class="adduserform">
      <el-form :model="form" label-width="80px" size="mini">
        <el-row>
          <el-col :span="12">
            <el-form-item label="选择导入文件 :" label-width="150px">
              <el-upload class="upload-demo" action="/file_upload" :on-preview="handlePreview" :on-remove="handleRemove"
                :on-success="fileUploadApi" multiple :limit="1" accept=".xls, .xlsx" :on-exceed="handleExceed"
                :file-list="fileList" :before-upload="beforeUpload" :data="fileData">
                <el-button size="mini" type="primary">点击上传</el-button>
                <span slot="tip" class="el-upload__tip updataMsg">
                  ( 说明：
                  <a href="/user.xls">点我 "下载模板"</a> 后填写完毕后上传 )
                </span>
              </el-upload>
            </el-form-item>
            <el-form-item label="所属部门: " label-width="150px">
              <el-select v-model="form.department_id">
                <el-option v-for="(item, key) in departmentList" :key="key" :label="item.name" :value="item.id">{{
                  item.name
                }}</el-option>
              </el-select>
            </el-form-item>
          </el-col> </el-row>量表权限
        <el-row class="right">
          <el-tabs v-model="scale" @tab-click="handleClick">
            <el-tab-pane label="所有可用量表" name="first">
              <div class="query">
                <p>量表权限设置</p>
                <el-form :inline="true" :model="form" size="mini">
                  <el-form-item>
                    <el-input v-model="form.scaleName" placeholder="量表名称"></el-input>
                  </el-form-item>
                  <el-form-item>
                    <el-button type="primary" @click="queryScale()">量表查询</el-button>
                  </el-form-item>
                  <el-form-item>
                    <el-button type="primary" @click="checkAll(1)">全选或取消所有量表</el-button>
                  </el-form-item>
                  <el-form-item>
                    <el-button type="primary" @click="checkAll(2)">全选或取消测评人查看结果</el-button>
                  </el-form-item>
                </el-form>
              </div>
              <!-- 所有量表 -->
              <div class="table" @change="handleCheckAllChange">
                <table>
                  <tr>
                    <th>量表名称</th>
                    <th>使用该量表</th>
                    <th>允许测评人看测评结果</th>
                    <th>测评次数</th>
                  </tr>
                  <!-- ----------------------- -->
                  <template v-for="(item, key) in scaleData">
                    <tr class="title" :key="key" v-if="item.status_measure.length > 0">
                      <td colspan="4">{{ item.classify_name }}</td>
                    </tr>
                    <tr v-for="item2 in item.status_measure" :key="item2.id">
                      <td>{{ item2.measure_title }}</td>
                      <td>
                        <el-checkbox-group v-model="checkList">
                          <el-checkbox :label="item2.id" name="a"></el-checkbox>
                        </el-checkbox-group>
                      </td>
                      <td>
                        <el-checkbox-group v-model="checkList2">
                          <el-checkbox :label="item2.id" name="b"></el-checkbox>
                        </el-checkbox-group>
                      </td>
                      <td>
                        <el-input v-model="item2.max_num" placeholder="" size="mini" :name="item2.id" maxlength="2"
                          onkeyup="value=value.replace(/[^\d]/g,'')"></el-input>
                      </td>
                    </tr>
                  </template>
                </table>
              </div>
              <el-col :span="5">
                <el-form-item label="已测量表是否显示" label-width="150px">
                  <el-radio-group v-model="form.show_finish_measure">
                    <el-radio :label="0">否</el-radio>
                    <el-radio :label="1">是</el-radio>
                  </el-radio-group>
                </el-form-item>
                <!-- <el-form-item label="每份量表测评次数" label-width="150px">
                  <el-input
                    v-model="form.measurement_num"
                    onkeyup="value=value.replace(/[^\d]/g,'')"
                  ></el-input>
                </el-form-item> -->
              </el-col>
            </el-tab-pane>
            <el-tab-pane label="量表组合包" name="second">
              <div class="query">
                <p>量表分组设置</p>
              </div>
              <div class="table">
                <table @change="handleCheckAllChange2">
                  <tr>
                    <th>分组量表名称</th>
                    <th>量表数量</th>
                    <!-- <th>次数限制</th> -->
                    <th>应用该分组</th>
                  </tr>
                  <!-- ----------------------- -->
                  <tr v-for="item in scaleData2.data" :key="item.id">
                    <td>{{ item.group_name }}</td>
                    <td>{{ item.measure_count }}</td>
                    <!-- <td>{{ item.test_num }}</td> -->
                    <td>
                      <el-checkbox-group v-model="checkList3">
                        <el-checkbox :label="item.id"></el-checkbox>
                      </el-checkbox-group>
                    </td>
                  </tr>
                </table>
              </div>
            </el-tab-pane>
          </el-tabs>
          <el-col :span="10">
            <el-form-item label="测评时间范围" label-width="150px">
              <!-- <el-date-picker
                v-model="form.date"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期（可不填写）"
                end-placeholder="结束日期（可不填写）"
                value-format="timestamp"
              ></el-date-picker> -->
              <div class="twoData">
                <el-date-picker v-model="form.date[0]" type="date" placeholder="开始日期（可不填写）" value-format="timestamp">
                </el-date-picker>
                至
                <el-date-picker v-model="form.date[1]" type="date" placeholder="结束日期（可不填写）" value-format="timestamp">
                </el-date-picker>
              </div>
            </el-form-item>
          </el-col>
        </el-row>
        <!-- 提交按钮 -->
        <div class="addUserBtn">
          <el-button type="primary" @click="importUserInfo">导入</el-button>
        </div>
      </el-form>
    </el-col>
  </div>
</template>

<script>
import axios from "axios";
import { log } from "util";
import {
  addUser,
  addUserAll,
  loadArchivesInfo,
  importUser,
} from "@/api/evaluate.js";
export default {
  inject: ["reload"],
  data() {
    return {
      fileData: {
        type: 5,
        attribution_id: 1, // 当前 用户的id
      },
      fileList: [],
      // 表单
      form: {
        department_id: "",
        groupId: 0, //组合量表包
        // measurement_num: 1,
        measure: [],
        show_finish_measure: 1,
        begin_time: 0,
        end_time: 0,
        date: [],
        filePath: "",
        source: 1,
      },
      // 用户tab栏
      activeName: "first",
      // 量表tab栏
      scale: "first",
      // 所有量表-是否使用
      checkList: [],
      // 所有量表-是否有查看权限
      checkList2: [],
      // 所有量表数据渲染
      scaleData: [],
      // 所有量表数据存储
      scaleData1: [],
      // 量表组合包-是否使用
      checkList3: [],
      //量表组合包数据
      scaleData2: [],
      // 所有量表-全选控制
      checkAll1: 1,
      checkAll2: 1,
      // 部门数据
      departmentList: [],
      imageUrl: "",
      optionalHide: false,
      msq: false,
    };
  },
  created() {
    // 量表加载
    this.loadScaleData();
    this.loadScaleData2();
    // 获取部门
    this.departmentList = JSON.parse(localStorage.getItem("departmentList"));
  },
  methods: {
    // 刷新
    refresh() {
      this.reload();
    },
    // 返回
    back() {
      this.$router.go(-1); //返回上一层
    },
    handleClick(tab, event) {
      //console.log(tab.name);
      if (tab.name == "first") {
        this.form.source = 1;
      } else {
        this.form.source = 2;
      }
    },
    //判断是否选择了量表，是否有权利选择结果,处理数据到  form.measure 中
    // handleCheckAllChange(val) {
    //   var a = parseInt(val.target.value);
    //   if (val.target.name === "a") {
    //     var obj = { id: a, show_result: "" || false };
    //     var tmp = this.form.measure.some(function (item) {
    //       return item.id === a;
    //     });
    //     if (tmp) {
    //       this.form.measure = this.form.measure.filter((v) => {
    //         return v.id != val.target.value;
    //       });
    //     } else {
    //       this.form.measure.push(obj);
    //     }
    //   }

    //   if (val.target.name === "b") {
    //     var p = this.checkList.indexOf(a);
    //     var index = this.form.measure.findIndex((item) => {
    //       return item.id === a;
    //     });
    //     if (this.form.measure[index] && index != -1) {
    //       if (this.form.measure[index].show_result === false) {
    //         this.form.measure[index].show_result = true;
    //       } else {
    //         this.form.measure[index].show_result = false;
    //       }
    //     }

    //     if (p === -1) {
    //       this.$message.error("请先选择该表单后，在选择此选项");
    //       this.checkList2 = this.checkList2.filter((v) => {
    //         return v != val.target.value;
    //       });
    //       return;
    //     }
    //   }
    // },
    handleCheckAllChange(val) {
      var a = parseInt(val.target.value);
      if (val.target.name === "a") {
        var obj = { id: a, show_result: 0 };
        var tmp = this.form.measure.some(function (item) {
          return item.id === a;
        });
        if (tmp) {
          this.form.measure = this.form.measure.filter((v) => {
            return v.id != val.target.value;
          });
        } else {
          this.form.measure.push(obj);
        }
      } else if (val.target.name === "b") {
        var p = this.checkList.indexOf(a);
        var index = this.form.measure.findIndex((item) => {
          return item.id === a;
        });
        if (this.form.measure[index] && index != -1) {
          if (this.form.measure[index].show_result === 0) {
            this.form.measure[index].show_result = 1;
          } else {
            this.form.measure[index].show_result = 0;
          }
        }

        if (p === -1) {
          this.$message.error("请先选择该表单后，在选择此选项");
          this.checkList2 = this.checkList2.filter((v) => {
            return v != val.target.value;
          });
          return;
        }
      } else {
        let st = true;
        this.form.measure.forEach((v) => {
          if (v.id == val.target.name) {
            v.max_num = val.target.value || 0;
            st = false;
          }
        });
        if (st) {
          this.$message({
            message: "警告,量表未勾选",
            type: "warning",
          });
          val.target.value = "";
        }
      }
    },
    // 全选、取消
    // checkAll(res) {
    //   // 量表
    //   if (res === 1) {
    //     if (this.checkAll1 === 1) {
    //       this.scaleData.forEach((v) => {
    //         v.status_measure.forEach((v) => {
    //           this.checkList.push(v.id);
    //           var obj = { id: v.id, show_result: true };
    //           this.form.measure.push(obj);
    //         });
    //       });
    //       this.checkAll1 = 0;
    //     } else {
    //       this.checkList = [];
    //       this.checkList2 = [];
    //       this.form.measure = [];
    //       this.checkAll1 = 1;
    //     }
    //   } else {
    //     if (this.checkAll2 === 1) {
    //       //1-先判断是否有选择的量表
    //       if (this.checkList <= 0) {
    //         this.$message.error("请选择量表后，再全选");
    //         return;
    //       }
    //       //2-把  checkList 的值 给 checkList2
    //       let a = this.checkList;
    //       this.checkList2 = a;
    //       //3-通过  checkList2 的值 依次 寻找measure 中的 show_result 改成 1
    //       this.checkList2.forEach((v, k) => {
    //         for (var i = 0; i < this.form.measure.length; i++) {
    //           if (this.form.measure[i].id === v) {
    //             this.form.measure[i].show_result = true;
    //           }
    //         }
    //       });
    //       this.checkAll2 = 0;
    //     } else {
    //       //1-先判断是否有选择的量表
    //       if (this.checkList <= 0) {
    //         this.$message.error("请选择量表后，再全选");
    //         return;
    //       }
    //       //3-通过  checkList2 的值 依次 寻找measure 中的 show_result 改成 1
    //       this.checkList2.forEach((v, k) => {
    //         for (var i = 0; i < this.form.measure.length; i++) {
    //           if (this.form.measure[i].id === v) {
    //             this.form.measure[i].show_result = false;
    //           }
    //         }
    //       });
    //       this.checkList2 = [];
    //       this.checkAll2 = 1;
    //     }
    //   }
    // },
    // 全选、取消
    checkAll(res) {
      // 量表
      if (res === 1) {
        if (this.checkAll1 === 1) {
          this.scaleData.forEach((v) => {
            v.status_measure.forEach((v) => {
              this.checkList.push(v.id);
              var obj = { id: v.id, show_result: 0, max_num: "1" };
              this.form.measure.push(obj);
            });
          });
          this.checkAll1 = 0;
        } else {
          this.checkList = [];
          this.checkList2 = [];
          this.form.measure = [];
          this.checkAll1 = 1;
        }
      } else {
        if (this.checkAll2 === 1) {
          //1-先判断是否有选择的量表
          if (this.checkList <= 0) {
            this.$message.error("请选择量表后，再全选");
            return;
          }
          //2-把  checkList 的值 给 checkList2
          let a = this.checkList;
          this.checkList2 = a;
          //3-通过  checkList2 的值 依次 寻找measure 中的 show_result 改成 1
          this.checkList2.forEach((v, k) => {
            for (var i = 0; i < this.form.measure.length; i++) {
              if (this.form.measure[i].id === v) {
                this.form.measure[i].show_result = 1;
              }
            }
          });
          this.checkAll2 = 0;
        } else {
          //1-先判断是否有选择的量表
          if (this.checkList <= 0) {
            this.$message.error("请选择量表后，再全选");
            return;
          }
          //3-通过  checkList2 的值 依次 寻找measure 中的 show_result 改成 1
          this.checkList2.forEach((v, k) => {
            for (var i = 0; i < this.form.measure.length; i++) {
              if (this.form.measure[i].id === v) {
                this.form.measure[i].show_result = 1;
              }
            }
          });
          this.checkList2 = [];
          this.checkAll2 = 1;
        }
      }
    },
    // 组合量表
    handleCheckAllChange2(val) {
      // 清除所有;
      this.checkList3 = [];
      this.checkList3.push(parseInt(val.target.value));
    },
    // 量表数据请求
    async loadScaleData() {
      var res = await axios.get("/all_measure_classify");
      if (res.data.code === 400200) {
        this.scaleData = res.data.data;
        this.scaleData1 = res.data.data;
      } else {
        return;
      }
    },
    //组合量表包请求
    async loadScaleData2() {
      var res = await axios.get("/list_measure_group?page=1&size=999");
      this.scaleData2 = res.data.data;
    },
    // 打开关闭自定义填写框
    openOptional() {
      if (!this.optionalHide) {
        this.optionalHide = true;
      } else {
        this.optionalHide = false;
      }
    },
    // 量表查询
    queryScale() {
      var str = this.form.scaleName;
      if (str === "") {
        this.scaleData = this.scaleData1;
        return;
      }
      var obj = [{ classify_name: "查询结果", id: 1, status_measure: [] }];
      for (let k in this.scaleData1) {
        if (this.scaleData1[k].id) {
          this.scaleData1[k].status_measure.forEach((v) => {
            if (v.measure_title.indexOf(str) != -1) {
              obj[0].status_measure.push(v);
            }
          });
        }
      }
      this.scaleData = obj;
    },
    // 文件上传
    // 文件上传成功的狗子
    fileUploadApi(response, file) {
      //console.log(response);
      if (response.code == 400200) {
        this.form.filePath = response.data.url;
        this.$confirm("文件上传成功！", "通知", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          showCancelButton: false,
          type: "success",
        })
          .then(() => { })
          .catch(() => { });
      } else {
        this.$message({
          showClose: true,
          message: response.msg,
          type: "error",
        });
      }
    },
    handleRemove(file, fileList) {
      //console.log(file, fileList);
    },
    handlePreview(file) {
      //console.log(file);
    },
    handleExceed(files, fileList) {
      this.$message.warning(
        `当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length
        } 个文件`
      );
    },
    beforeUpload(file) {
      //console.log(file);
      var testmsg = file.name.substring(file.name.lastIndexOf(".") + 1);
      const extension = testmsg === "xls";
      const extension2 = testmsg === "xlsx";
      // const isLt2M = file.size / 1024 / 1024 < 10
      if (!extension && !extension2) {
        this.$message({
          message: "上传文件只能是 xls、xlsx格式!",
          type: "warning",
        });
      }
      return extension || extension2;
    },
    // 导入
    importUserInfo() {
      let begin_time = 0;
      let end_time = 0;
      if (this.form.date[0] || this.form.date[1]) {
        if (!this.form.date[0] || !this.form.date[1]) {
          this.$message({
            message: "请选择完整时间范围或者不选择时间范围!",
            type: "error",
          });
          return
        }
      }
      // console.log("长度",this.form.date.length);
      if (this.form.date.length >= 2) {
        if (this.form.date[0] > this.form.date[1]) {
          this.$message({
            message: "评测时间范围结束时间请大于开始时间!",
            type: "error",
          });
          return
        }
        this.form.begin_time = parseInt(this.form.date[0] / 1000);
        this.form.end_time = parseInt(this.form.date[1] / 1000);
      } else {
        this.form.begin_time = 0;
        this.form.end_time = 0;
        this.form.date = []
      }
      let obj = {};
      if (this.scale === "first") {
        delete this.form.groupId;
        // 所有量表
        // var { measurement_num, show_finish_measure, measure } = this.form;
        var { show_finish_measure, measure } = this.form;
        // this.form.measurement_num = measurement_num;
        this.form.show_finish_measure = show_finish_measure;
      } else {
        // 量表组
        delete this.form.groupId;
        // this.form.measurement_num = this.form.measurement_num;
        this.form.groupId = this.checkList3[0];
      }
      importUser(this.form).then((res) => {
        if (res.code == 400200) {
          this.$message({
            showClose: true,
            message: "导入成功！",
            type: "success",
          });
          this.$router.push("/user_evaluate");
        }
      });
    },
  },
  watch: {},
};
</script>

<style lang="less">
.tc-evaluate-bulkImport {
  width: 100%;
  min-width: 1300px;
  position: relative;

  .tool {
    position: absolute;
    top: -50px;
    right: 0;

    ul {
      li {
        cursor: pointer;
        list-style: none;
        float: left;
        margin-left: 10px;

        img {
          width: 100px;
        }
      }
    }
  }

  .updataMsg {
    margin-left: 20px;
  }

  .adduserform {
    margin-top: 20px;
    width: 100%;

    .left {
      .single {
        .el-input__inner {
          width: 200px;
        }
      }

      .multi {
        .el-input__inner {
          width: 100px;
        }

        // 用户信息
        .serial {
          display: flex;

          .serial-from {
            display: flex;
          }
        }

        .serial-repair {
          display: flex;

          .serial-repair-from {
            display: flex;

            .num {
              display: flex;

              span {
                line-height: 28px;
                font-size: 14px;
                color: #606266;
                margin-bottom: 10px;
                padding-left: 10px;
              }
            }
          }
        }

        .number {
          display: flex;

          .number-from {
            display: flex;
            flex-direction: column;

            .number-from-1 {
              display: flex;
            }

            .radio {
              .el-form-item__content {
                margin-left: 20px !important;
              }
            }

            .input {}
          }
        }

        .pass {
          display: flex;

          .pass-from {
            display: flex;
            flex-direction: column;

            .pass-from-1 {
              display: flex;
            }

            .radio {
              .el-form-item__content {
                margin-left: 20px !important;
              }
            }

            .input {
              .el-form-item__content {
                margin-left: 10px !important;
              }

              .el-input__inner {
                width: 150px;
                padding-right: 20px;
              }
            }
          }
        }

        p {
          line-height: 28px;
          font-size: 14px;
          color: #606266;
          margin-bottom: 10px;
          padding-left: 10px;
        }

        .title {
          width: 130px;
        }

        .ps {
          width: 300px;
        }
      }
    }

    .right {}
  }

  // tab内边距
  .el-tab-pane {
    // padding-left: 40px;
  }

  .table {
    width: 850px;
    height: 300px;
    overflow: auto;
    border: 1px #cccccc solid;
    margin-bottom: 20px;

    .el-input__inner {
      width: 120px;
      padding-right: 20px;
    }

    table {
      width: 848px;
      text-align: center;
      overflow: auto;

      tr,
      th {
        height: 20px;
        padding: 0;
        font-weight: 300;
        font-size: 14px;
      }

      tr {
        td {
          &:nth-child(1) {
            text-align: left;
          }
        }
      }

      th {
        height: 30px;
        font-size: 15px;
        background: rgb(224, 245, 250);
      }

      .title {
        background: rgb(224, 245, 250);
        text-align: left;
        font-weight: 700;
        font-size: 15px;
      }

      .el-checkbox__label {
        display: none;
      }
    }
  }

  .query {
    p {
      line-height: 30px;
      font-size: 14px;
      color: #606266;
      margin-bottom: 10px;
      padding-left: 10px;
    }
  }

  .extend {
    color: rgb(12, 118, 172);
    margin: 5px;
    cursor: pointer;
    text-decoration: underline;
  }

  .addUserBtn {
    margin-top: 10px;
    text-align: center;

    // .el-form-item__content {
    //   margin-left: 0 !important;
    // }
    img {
      width: 100px;
    }
  }

  .el-form-item--mini.el-form-item,
  .el-form-item--small.el-form-item {
    margin-bottom: 16px;
  }

  .optional {
    padding-bottom: 10px;

    // 头像
    .avatar-uploader .el-upload {
      border: 1px dashed #d9d9d9;
      border-radius: 6px;
      cursor: pointer;
      position: relative;
      overflow: hidden;
    }

    .avatar-uploader .el-upload:hover {
      border-color: #409eff;
    }

    .avatar-uploader-icon {
      font-size: 28px;
      color: #8c939d;
      width: 100px;
      height: 100px;
      line-height: 100px;
      text-align: center;
    }

    .avatar {
      width: 100px;
      height: 100px;
      display: block;
    }

    .avatar-uploader {
      // display: flex;
      // justify-content: center;
      padding-left: 110px;
      padding-bottom: 10px;
    }

    p {
      padding-left: 148px;
      padding-bottom: 5px;
      font-size: 14px;
      color: #606266;
    }

    .el-col {
      padding-right: 1 0px;
    }
  }
}

.twoData {
  display: flex;

  .el-date-editor {
    width: unset;

    .el-input__inner {
      width: 174px !important;
    }
  }
}
</style>